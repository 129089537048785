<template>
  <div :class="[theme.toLowerCase()]">
    <container>
      <div>
        <prismic-text :blocks="copy" />
        <div class="speakers">
          <app-modal
            v-for="(speaker, index) in speakers"
            :key="`speaker${index}`"
          >
            <template v-slot:modal v-if="speaker">
              <speaker-bio :speaker="speaker" />
            </template>
            <template v-slot:link v-if="speaker">
              <div class="image">
                <div
                  :style="{
                    backgroundImage: `url(${
                      speaker.photo ? speaker.photo.url : null
                    })`
                  }"
                ></div>
              </div>
              <div>
                <div class="name">{{ speaker.name }}</div>
                <div class="title">{{ speaker.title }}</div>
              </div>
            </template>
          </app-modal>
          <!-- These four divs are needed to left-align trailing items since flex doesn't support that -->
          <div class="tablet-up"></div>
          <div class="tablet-up"></div>
          <div class="tablet-up"></div>
          <div class="tablet-up"></div>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import AppModal from "@/components/AppModal";
import Container from "@/components/Container";
import PrismicText from "@/components/PrismicText";
import SpeakerBio from "@/components/SpeakerBio";

export default {
  name: "SliceSpeakers",
  components: {
    AppModal,
    Container,
    PrismicText,
    SpeakerBio
  },
  props: {
    theme: {
      type: String,
      default: ""
    },
    copy: {
      type: Array,
      default: () => []
    },
    speakers: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  ::v-deep {
    h2 {
      margin: 0 auto 24px;
    }
  }

  .speakers {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    > div {
      width: calc(20% - 25px);
      margin-top: 33px;

      &:hover {
        .image div {
          opacity: 0.8;
          background-size: 104%;
        }
      }

      @include tablet {
        width: 100%;
        display: flex;

        > div {
          flex-basis: 0;
          flex-grow: 1;
        }
      }

      .image {
        margin-bottom: 13px;

        div {
          padding-bottom: 100%;
          opacity: 1;
          background-size: 100%;
          background-position: center;
          transition: opacity 100ms, background-size 100ms;
          border-radius: 8px 8px 0 0;

          @include tablet {
            border-radius: 8px;
          }
        }

        @include tablet {
          max-width: 25%;
          margin-right: 16px;
        }
      }

      .name {
        color: $blue2;
        font-size: rem-calc(24px);
        line-height: rem-calc(36px);
      }

      .title {
        font-size: rem-calc(18px);
        line-height: rem-calc(25px);
      }
    }
  }
}
</style>
