<template>
  <section>
    <slot />
  </section>
</template>

<script>
export default {
  name: "Container"
};
</script>

<style lang="scss" scoped>
section {
  margin-left: auto;
  margin-right: auto;
  max-width: 1156px;
  padding: 100px 32px;

  @include mobile {
    padding: 32px 16px;
  }
}
</style>
