<template>
  <footer>
    <container>
      <div>
        <div>
          <prismic-text v-if="content.copy" :blocks="content.copy" />
        </div>
        <collaborators-box
          :collaborators="content.collaborators"
          v-if="content.collaborators.length"
        />
      </div>
      <div v-if="content.copyright" class="copyright">
        {{ content.copyright }}
      </div>
    </container>
  </footer>
</template>

<script>
import CollaboratorsBox from "@/components/CollaboratorsBox";
import Container from "@/components/Container";
import PrismicText from "@/components/PrismicText.vue";
import gql from "graphql-tag";

export default {
  name: "AppFooter",
  components: {
    CollaboratorsBox,
    Container,
    PrismicText
  },
  data() {
    return {
      content: {
        collaborators: []
      }
    };
  },
  apollo: {
    content: {
      query: gql`
        query {
          footer(uid: "footer", lang: "en-gb") {
            copy
            copyright
            collaborators {
              ... on FooterCollaborators {
                link {
                  ... on Page {
                    _meta {
                      uid
                    }
                  }
                  ... on _ExternalLink {
                    url
                    target
                  }
                }
                logo {
                  ... on _ImageLink {
                    url
                  }
                }
              }
            }
          }
        }
      `,
      update: data => data.footer
    }
  }
};
</script>

<style lang="scss" scoped>
footer {
  width: 100%;
  background-color: $blue2;
  color: $white1;

  section {
    @include mobile {
      padding-top: 24px;
    }

    > div {
      display: flex;
      justify-content: space-between;

      @include mobile {
        flex-direction: column;
      }

      ::v-deep {
        h3 {
          margin-bottom: 14px;

          @include mobile {
            font-size: rem-calc(14px);
          }
        }

        h4 {
          @include mobile {
            font-size: rem-calc(25px);
          }
        }

        h5 {
          @include mobile {
            font-size: rem-calc(21px);
            line-height: 30px;
            margin-top: 8px;
          }
        }
      }

      .collaborators {
        width: 33%;

        @include mobile {
          width: 60%;
          margin: 0 auto;
          margin-top: 26px;
        }
      }
    }

    .copyright {
      margin-top: 64px;
      font-size: rem-calc(12px);
      color: $grey3;

      @include mobile {
        text-align: center;
        font-size: rem-calc(14px);
      }
    }
  }
}
</style>
