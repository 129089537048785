<template>
  <div
    :style="{ backgroundImage: backgroundUrl ? `url(${backgroundUrl})` : '' }"
    :class="{
      'align-left': alignment == 'Left' || !alignment,
      'align-right': alignment == 'Right',
      'align-centre': alignment == 'Centre',
      [theme.toLowerCase()]: true
    }"
    class="background"
  >
    <container>
      <div>
        <prismic-text :blocks="copy" />
        <prismic-link :link="buttonLink" v-if="buttonLink">
          <app-button :class="{ secondary: backgroundColour == 'dark' }">{{
            buttonText
          }}</app-button>
        </prismic-link>
      </div>
      <div class="image" v-if="imageUrl">
        <img :src="imageUrl" />
      </div>
    </container>
  </div>
</template>

<script>
import AppButton from "@/components/AppButton";
import Container from "@/components/Container";
import PrismicLink from "@/components/PrismicLink";
import PrismicText from "@/components/PrismicText";

export default {
  name: "SliceFeature",
  components: {
    AppButton,
    Container,
    PrismicLink,
    PrismicText
  },
  props: {
    theme: {
      type: String,
      default: ""
    },
    copy: {
      type: Array,
      default: () => []
    },
    buttonLink: {
      type: Object,
      default: () => {}
    },
    buttonText: {
      type: String,
      default: ""
    },
    backgroundColour: {
      type: String,
      default: ""
    },
    backgroundUrl: {
      type: String,
      default: ""
    },
    imageUrl: {
      type: String,
      default: ""
    },
    alignment: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.background {
  background-size: cover;
  background-position: 50%;
}

section {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;

  ::v-deep {
    h2 {
      margin: 0 auto;
    }
  }

  > div {
    flex-basis: 0;
    flex-grow: 1;

    &.image {
      max-width: 40%;

      @include mobile {
        display: none;
      }

      img {
        width: 100%;
        border-radius: 8px;
      }
    }
  }
}

.align-left {
  section {
    .image {
      margin-left: 24px;
    }
  }
}

.align-right {
  section {
    flex-direction: row-reverse;

    .image {
      margin-right: 24px;
    }
  }
}

.align-centre {
  section {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;

    .image {
      margin-bottom: 24px;
    }
  }
}
</style>
