<template>
  <div class="speaker-bio white">
    <div class="details">
      <h2>{{ speaker.name }}</h2>
      <h4>{{ speaker.title }}</h4>
      <prismic-text :blocks="speaker.bio || []" />
    </div>
    <div class="photo">
      <img :src="speaker.photo ? speaker.photo.url : null" />
    </div>
  </div>
</template>

<script>
import PrismicText from "@/components/PrismicText";

export default {
  name: "SpeakerBio",
  components: {
    PrismicText
  },
  props: {
    speaker: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.speaker-bio {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 6%;

  @include tablet {
    margin: 16px;
    flex-direction: column-reverse;
  }

  .details {
    margin-right: 5%;
    font-size: rem-calc(18px);
    overflow-y: auto;
    max-height: 60vh;

    h4 {
      font-size: rem-calc(24px);
      margin-top: 8px;
      margin-bottom: 24px;
    }
  }

  .photo {
    min-width: 30%;
    max-width: 30%;

    @include tablet {
      max-width: none;
      text-align: center;
      margin-bottom: 24px;
    }

    img {
      border-radius: 8px;
      width: 100%;

      @include tablet {
        max-width: 30%;
      }
    }
  }
}
</style>
