<template>
  <div :class="[theme.toLowerCase()]">
    <container>
      <div>
        <prismic-text :blocks="copy" />
        <prismic-link :link="buttonLink">
          <app-button>{{ buttonText }}</app-button>
        </prismic-link>
      </div>
      <div v-for="(event, index) in events" :key="`event${index}`" :class="{ 'past-event': pastEvent(event) }">
        <div v-if="!pastEvent(event) || !fullInfo" class="times">
          {{ formatTime(event.start_time) }} -<br />
          {{ formatTime(event.end_time) }} BST
          <div
            v-for="(other_tz, index) in event.other_tz"
            :key="`other_tz${index}`"
          >
            {{ formatTime(other_tz.other_tz_start_time) }}-{{
              formatTime(other_tz.other_tz_end_time)
            }}
            {{ other_tz.other_tz_abbr }}
          </div>
        </div>
        <div v-else class="times">
          {{ event.duration }}
          <div v-if="event.duration">Duration</div>
        </div>
        <div>
          <h3 class="title">{{ event.title }}</h3>
          <div class="subtitle">{{ event.subtitle }}</div>
          <prismic-text
            class="description"
            :blocks="event.description"
            v-if="event.description && fullInfo"
          />
          <div class="speakers" v-if="fullInfo && !pastEvent(event)">
            <div
              v-for="(speakers, group) in groupSpeakers(event.speakers)"
              :key="`speakergroup${group}`"
            >
              <div class="group">{{ group }}:</div>
              <app-modal
                class="speaker"
                v-for="(speaker, index) in speakers.map(obj => obj.speaker)"
                :key="`eventspeaker${index}`"
              >
                <template v-slot:modal v-if="speaker">
                  <speaker-bio :speaker="speaker" />
                </template>
                <template v-slot:link v-if="speaker">
                  <div
                    class="image"
                    :style="{
                      backgroundImage: `url(${
                        speaker.photo ? speaker.photo.url : null
                      })`
                    }"
                  ></div>
                  <div>
                    <div class="name">{{ speaker.name }}</div>
                    <div class="title">{{ speaker.title }}</div>
                  </div>
                </template>
              </app-modal>
              <div class="speaker"></div>
            </div>
          </div>
        </div>
        <div class="speakers" v-if="!fullInfo || pastEvent(event)">
          <app-modal
            v-for="(speaker, index) in event.speakers.map(obj => obj.speaker)"
            :key="`eventspeaker${index}`"
          >
            <template v-slot:modal v-if="speaker">
              <speaker-bio :speaker="speaker" />
            </template>
            <template v-slot:link v-if="speaker">
              <div
                class="image"
                :style="{
                  backgroundImage: `url(${
                    speaker.photo ? speaker.photo.url : null
                  })`
                }"
              ></div>
            </template>
          </app-modal>
        </div>
      </div>
    </container>
  </div>
</template>

<script>
import AppButton from "@/components/AppButton";
import AppModal from "@/components/AppModal";
import Container from "@/components/Container";
import PrismicLink from "@/components/PrismicLink";
import PrismicText from "@/components/PrismicText";
import SpeakerBio from "@/components/SpeakerBio";

export default {
  name: "SliceSchedule",
  components: {
    AppButton,
    AppModal,
    Container,
    PrismicLink,
    PrismicText,
    SpeakerBio
  },
  props: {
    fullInfo: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: ""
    },
    copy: {
      type: Array,
      default: () => []
    },
    buttonLink: {
      type: Object,
      default: () => {}
    },
    buttonText: {
      type: String,
      default: ""
    },
    events: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    formatTime(dateString) {
      // Using GMT-2 and getting a UTC date is an ugly hack for stopping these dates
      // being localised in the browser, and accommodating for Prismic only supporting
      // localised dates being configured in their UI
      const date = new Date(
        new Date(dateString).toLocaleString("en-US", { timeZone: "Etc/GMT-1" })
      );

      return `${date
        .getHours()
        .toString()
        .padStart(2, "0")}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
    },
    groupSpeakers(speakers) {
      return speakers.reduce((objectsByKeyValue, obj) => {
        const value = obj.group;
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
      }, {});
    },
    pastEvent(event) {
      return Date.parse(event.end_time) < Date.now();
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  ::v-deep {
    h2 {
      margin: 0 auto;
    }

    h3 {
      margin-bottom: 10px;
    }
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 32px 0;
    border-bottom: 1px solid $grey2;
    position: relative;

    @include mobile {
      flex-direction: column;
      padding: 24px 0;

      &:last-of-type {
        border-bottom: 0 none;
        padding-bottom: 0;
      }
    }

    @include desktop-only {
      &.past-event {
        > div {
          .title, .subtitle {
            margin-left: 0;
            max-width: calc(75% - 72px);
          }

          &:last-of-type {
            position: absolute;
            right: 0;
          }
        }
      }
    }

    &:first-of-type {
      align-items: center;
      padding-top: 0;

      @include mobile {
        flex-direction: column;
        align-items: flex-start;

        .prismic-link {
          margin-top: 16px;
        }
      }
    }

    > div {
      flex-basis: 0;
      flex-grow: 1;

      .title {
        margin-top: 0;
        font-size: rem-calc(18px);
      }

      .subtitle {
        margin-top: 0;
        font-size: rem-calc(32px);

        @include mobile {
          font-size: rem-calc(25px);
          line-height: rem-calc(33px);
          margin-bottom: 16px;
        }
      }

      .description {
        font-size: rem-calc(18px);
      }

      .speakers {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: 500px;
        font-size: rem-calc(18px);

        @include tablet {
          flex-direction: column;
        }

        > div {
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          min-width: 25%;
          margin-bottom: 24px;
          max-width: 25%;

          @include tablet {
            margin-bottom: 16px;
            max-width: none;
          }

          &:last-child {
            max-width: 75%;

            .speaker {
              flex-basis: calc(33% - 24px);

              @include tablet {
                flex-basis: unset;
              }
            }
          }

          .group {
            margin-bottom: 16px;
            min-width: 100%;

            @include tablet {
              margin-bottom: 8px;
              font-size: rem-calc(12px);
            }
          }

          .speaker {
            margin-right: 24px;
            flex-basis: calc(50% - 24px);
            flex-grow: 1;
            display: flex;

            &:hover {
              .image {
                opacity: 0.8;
                background-size: 104%;
              }
            }

            @include tablet {
              flex-basis: unset;
              flex-grow: unset;
              margin-right: 8px;
            }

            > div {
              margin-bottom: 24px;

              @include tablet {
                margin-bottom: 8px;
              }

              &.image {
                min-width: 64px;
                height: 64px;
                border-radius: 8px;
                margin-right: 16px;
                opacity: 1;
                background-size: 100%;
                background-position: center;
                transition: opacity 100ms, background-size 100ms;

                @include tablet {
                  margin: 0;
                  min-width: 34px;
                  height: 34px;
                }
              }

              .name {
                color: $blue2;

                @include tablet {
                  display: none;
                }
              }

              .title {
                font-size: rem-calc(12px);
                color: $grey4;

                @include tablet {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    .times {
      max-width: calc(25% - 24px);
      margin-right: 24px;
      font-size: rem-calc(32px);

      @include mobile {
        max-width: none;
        margin-bottom: 8px;
        margin-right: 0;
        font-size: rem-calc(25px);
      }

      div {
        color: $grey3;
        font-size: rem-calc(18px);
      }
    }

    > .speakers {
      max-width: calc(25% - 24px);
      margin-left: 24px;
      font-size: rem-calc(32px);

      @include mobile {
        max-width: none;
        margin-left: 0;
        font-size: rem-calc(25px);
      }

      > div {
        display: inline;

        .image {
          display: inline-block;
          width: 40px;
          height: 40px;
          border-radius: 8px;
          margin-right: 8px;
          opacity: 1;
          background-size: 100%;
          background-position: center;
          transition: opacity 100ms, background-size 100ms;

          &:hover {
            opacity: 0.8;
            background-size: 104%;
          }

          @include mobile {
            width: 34px;
          }
        }
      }
    }
  }
}

.light {
  .times,
  .subtitle {
    color: $blue2;
  }
}
</style>
