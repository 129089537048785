<template>
  <div v-if="nonBlank">
    <template v-for="(block, index) in parsedBlocks">
      <div :key="index" v-if="block.type == 'image'" class="image">
        <img :src="block.url" />
      </div>
      <ul :key="index" v-else-if="block.type == 'ul'">
        <li
          v-for="(item, itemIndex) in block.items"
          :key="`${index}_${itemIndex}`"
          v-html="item.text"
        />
      </ul>
      <ol :key="index" v-else-if="block.type == 'ol'">
        <li
          v-for="(item, itemIndex) in block.items"
          :key="`${index}_${itemIndex}`"
          v-html="item.text"
        />
      </ol>
      <h1 :key="index" v-else-if="block.type == 'heading1'" v-html="block.text"></h1>
      <h2 :key="index" v-else-if="block.type == 'heading2'" v-html="block.text"></h2>
      <h3 :key="index" v-else-if="block.type == 'heading3'" v-html="block.text"></h3>
      <h4 :key="index" v-else-if="block.type == 'heading4'" v-html="block.text"></h4>
      <h5 :key="index" v-else-if="block.type == 'heading5'" v-html="block.text"></h5>
      <h6 :key="index" v-else-if="block.type == 'heading6'" v-html="block.text"></h6>
      <prismic-link
        :key="index"
        :link="{ url: getLinkUrl(block.text), target: '_blank' }"
        v-else-if="block.type == 'paragraph' && block.text.includes('<a ')"
      >
        <app-button>
          {{ getLinkText(block.text) }}
        </app-button>
      </prismic-link>
      <p
        :key="index"
        v-else-if="block.type == 'paragraph'"
        v-html="block.text"
      ></p>
      <div
        :key="index"
        v-else-if="block.type == 'embed'"
        class="embed"
        v-html="block.oembed.html"
      ></div>
      <div :key="index" v-else v-html="block.text" />
    </template>
  </div>
</template>

<script>
import AppButton from "@/components/AppButton";
import PrismicLink from "@/components/PrismicLink";

export default {
  name: "PrismicText",
  components: {
    AppButton,
    PrismicLink
  },
  props: {
    blocks: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    nonBlank() {
      return this.parsedBlocks.filter(block => block.text).length > 0;
    },
    parsedBlocks() {
      // This parses the text within each block, injecting markup from Prismic as defined in "spans"
      const blocks = this.blocks.map(block => {
        if (block.spans && block.spans.length) {
          // Reverse the spans since character indexes will change as we inject
          block.spans.sort((a, b) => b.start - a.start);

          block.spans.forEach((span, index) => {
            let type, attrs;

            if (span.type == "hyperlink") {
              type = "a";
            } else {
              type = span.type;
            }

            if (!type) {
              return;
            }

            if (span.data) {
              attrs = ` href="${span.data.url}" target="${span.data.target ||
                ""}"`;
            } else {
              attrs = "";
            }

            block.text = [
              block.text.slice(0, span.start),
              `<${type}${attrs}>`,
              block.text.slice(span.start, span.end),
              `</${type}>`,
              block.text.slice(span.end)
            ].join("");

            delete block.spans[index]; // Prevent span being re-applied
          });
        }

        return block;
      });

      // This finds list items and wraps them in a ul or ol
      const grouped = [];

      let subgroup = {};

      blocks.forEach(block => {
        if (block.type == "list-item") {
          subgroup.type = "ul";

          if (!subgroup.items) {
            subgroup.items = [block];
          } else {
            subgroup.items.push(block);
          }
        } else if (block.type == "o-list-item") {
          subgroup.type = "ol";

          if (!subgroup.items) {
            subgroup.items = [block];
          } else {
            subgroup.items.push(block);
          }
        } else {
          if (Object.keys(subgroup).length) {
            grouped.push(subgroup);

            subgroup = {};
          }

          grouped.push(block);
        }
      });

      if (
        this.blocks.length &&
        ["list-item", "o-list-item"].includes(
          this.blocks[this.blocks.length - 1].type
        )
      ) {
        grouped.push(subgroup);
      }

      return grouped;
    }
  },
  methods: {
    getLinkUrl(string) {
      return string.match(/<a href="([^"]+)"/)[1]
    },
    getLinkText(string) {
      return string.match(/<.+>(.+?)<.+>/)[1]
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  margin: 120px auto;

  @include mobile {
    margin-top: 40px;
  }

  .image {
    text-align: center;

    img {
      width: 80%;

      @include mobile {
        width: 100%;
      }
    }
  }

  p {
    min-height: 0.1px;
  }

  .embed {
    overflow: hidden;
    /* 16:9 aspect ratio */
    padding-top: 56.25%;
    position: relative;

    ::v-deep iframe {
      border: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}
</style>
