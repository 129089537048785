import Vue from "vue";
import VueRouter from "vue-router";
import NotFound from "@/pages/NotFound.vue";
import Preview from "@/pages/Preview.vue";
import Template from "@/pages/Template";

Vue.use(VueRouter);

export default new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: Template,
      props: {
        uid: "index"
      }
    },
    {
      path: "/not-found",
      name: "not-found",
      component: NotFound
    },
    {
      path: "/preview",
      name: "preview",
      component: Preview
    },
    {
      path: "/:uid",
      component: Template,
      props: true
    }
  ]
});
