import Vue from "vue";
import App from "@/App.vue";
import router from "@/plugins/vue-router";
import "@/plugins/vue-meta";
import "@/plugins/vue-prismic";
import { createProvider } from "@/plugins/vue-apollo";
import "regenerator-runtime/runtime.js";

Vue.config.productionTip = false;

new Vue({
  apolloProvider: createProvider(),
  router,
  render: h => h(App)
}).$mount("#app");
