<template>
  <div
    class="modal-wrapper"
    @click="showModal = true"
    :class="{ closed: !showModal }"
  >
    <transition name="fade" mode="out-in" appear>
      <div class="modal" v-if="showModal" @click.stop="showModal = false">
        <div @click.stop>
          <div class="close" @click.stop="showModal = false"></div>
          <slot name="modal" />
        </div>
      </div>
    </transition>
    <slot name="link" />
  </div>
</template>

<script>
export default {
  name: "AppModal",
  data() {
    return {
      showModal: false
    };
  }
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  &.closed {
    cursor: pointer;
  }

  .modal {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $blue-trans1;
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      position: relative;
      background-color: $white1;
      width: 50%;
      padding: 1%;
      border-radius: 8px;
      box-shadow: 0 -10px 40px 0 rgba(0, 0, 0, 0.1);

      @include mobile {
        padding: 24px 0 24px 0;
        width: auto;
      }

      .close {
        position: absolute;
        top: 30px;
        right: 30px;

        &::after {
          content: "\00d7";
          width: 20px;
          height: 20px;
          cursor: pointer;
          font-family: sans-serif;
          font-size: rem-calc(30px);
          font-weight: lighter;
          line-height: 0.7;
          display: block;
          color: $grey1;
        }
      }
    }
  }
}
</style>
