<template>
  <button>
    <slot />
  </button>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
button {
  font-size: rem-calc(18px);
  border: 0 none;
  background-color: $turquoise1;
  padding: 0 16px;
  text-align: center;
  cursor: pointer;

  &.secondary {
    background-color: white;
    color: $blue1;
  }
}
</style>
