<template>
  <div class="collaborators" v-if="collaborators">
    <slot />
    <div>
      <prismic-link
        v-for="(collaborator, index) in collaborators"
        :key="`collaborator${index}`"
        :link="collaborator.link"
      >
        <img :src="collaborator.logo.url" />
      </prismic-link>
    </div>
  </div>
</template>

<script>
import PrismicLink from "@/components/PrismicLink";

export default {
  name: "CollaboratorsBox",
  components: {
    PrismicLink
  },
  props: {
    collaborators: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.collaborators {
  font-size: rem-calc(18px);

  div {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    a {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 45%;

      img {
        width: 100%;
      }
    }
  }
}
</style>
