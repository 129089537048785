<template>
  <div id="app">
    <cookie-bar />
    <app-header />
    <main>
      <transition name="fade" mode="out-in" appear>
        <router-view :key="$route.path" />
      </transition>
    </main>
    <app-footer />
  </div>
</template>

<script>
import AppFooter from "@/components/AppFooter";
import AppHeader from "@/components/AppHeader";
import CookieBar from "@/components/CookieBar";

export default {
  name: "App",
  components: {
    AppFooter,
    AppHeader,
    CookieBar
  },
  metaInfo: {
    titleTemplate: title =>
      title ? `${title} | Rethinking Protein` : "Rethinking Protein"
  }
};
</script>

<style lang="scss">
body {
  margin: 0;
}

#app {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: $white1;
  color: $grey1;
  font-family: $font-body;
  font-size: rem-calc(24px);
  line-height: 1.4;

  @include tablet {
    font-size: rem-calc(20px);
  }

  main {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: stretch;

    article {
      width: 100%;
    }
  }
}

// Fade in/out transitions for pages
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

a {
  color: $grey1;
  font-weight: bold;
}

h1 {
  font-family: $font-primary;
  font-size: rem-calc(76px);
  font-weight: normal;
  line-height: 1.2;
  margin: 0;

  @include mobile {
    font-size: rem-calc(42px);
  }
}

h2 {
  font-family: $font-primary;
  font-size: rem-calc(42px);
  font-weight: normal;
  line-height: 1.2;
  margin-bottom: rem-calc(15px);

  @include mobile {
    font-size: rem-calc(30px);
  }
}

h3 {
  font-family: $font-secondary;
  font-size: rem-calc(14px);
  font-weight: normal;
  line-height: 1.2;
  color: $turquoise1;
  margin: 0 auto;

  @include mobile {
    font-size: rem-calc(20px);
  }
}

h4 {
  font-family: $font-body;
  font-size: rem-calc(32px);
  font-weight: normal;
  line-height: 1.2;
  margin: 0 auto;

  @include mobile {
    font-size: rem-calc(18px);
  }
}

h5 {
  font-family: $font-body;
  font-size: rem-calc(24px);
  font-weight: normal;
  line-height: 1.2;
  margin: 0 auto;

  @include mobile {
    font-size: rem-calc(18px);
  }
}

p {
  margin-bottom: 24px;
}

ul {
  padding: 0;
  list-style-type: none;

  li {
    position: relative;
    padding-left: 30px;
    margin-left: 20px;

    &::before {
      content: "";
      position: absolute;
      width: 4px;
      height: 4px;
      background-color: $grey1;
      border-radius: 50%;
      left: 0;
      top: 10px;
    }
  }
}

input,
button {
  line-height: 60px;
  color: $blue2;
  font-weight: 600;
  font-size: 16px;

  @include mobile {
    line-height: 48px;
  }
}

input[type="text"],
input[type="email"] {
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0 24px;
  margin-right: 24px;

  &::placeholder {
    text-transform: uppercase;
    color: #fff;
    opacity: 1;
  }

  &:focus {
    border-color: #fff;
  }
}

input[type="checkbox"] {
  display: none;

  & + label::before {
    content: "";
  }

  &:checked + label::before {
    content: "\e902";
  }
}

input[type="radio"] {
  & + label::before {
    border-radius: 50%;
  }
}

input[type="radio"] {
  display: none;

  & + label::before {
    content: "";
    border-radius: 50%;
  }

  &:checked + label::after {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
  }
}

label {
  position: relative;
  text-align: left;
  padding-left: 30px;
  cursor: pointer;

  &::before {
    font-family: $font-icons;
    font-size: rem-calc(10);
    position: absolute;
    width: 12px;
    height: 12px;
    padding: 2px;
    left: 0;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border: 2px solid rgba(255, 255, 255, 0.5);
  }
}

.light {
  background-color: $blue1;
  color: $grey1;

  h1,
  h2,
  h3 {
    color: $blue2;
  }
}

.dark {
  background-color: $blue2;
  color: $white1;

  h1,
  h2 {
    color: $white1;
  }
}

.white {
  background-color: $white1;
  color: $grey1;

  h1,
  h2 {
    color: $blue2;
  }
}
</style>
