<template>
  <header>
    <container>
      <nav :class="{ open: navOpen }" @click="navOpen = !navOpen">
        <prismic-link
          v-for="(nav, index) in content.navigation_left"
          :key="`nav${index}`"
          :link="nav.link_url"
          :class="{
            active: activePath(nav.link_url)
          }"
        >
          <span class="tablet-up">{{ nav.link_copy }}</span>
          <img
            class="mobile-only"
            src="~@/assets/icons/home.svg"
            :alt="nav.link_copy"
            v-if="nav.link_copy == 'Home'"
          />
          <img
            class="mobile-only"
            src="~@/assets/icons/schedule.svg"
            :alt="nav.link_copy"
            v-else
          />
        </prismic-link>
      </nav>
      <prismic-link
        v-for="(nav, index) in content.navigation_right"
        :key="`nav${index}`"
        :link="nav.link_url"
        :class="{
          active:
            nav.link_url._meta && $route.path == `/${nav.link_url._meta.uid}`
        }"
      >
        <app-button
          ><span class="tablet-up">{{ nav.link_copy }}</span
          ><span class="mobile-only">{{
            nav.link_copy.split(" ")[0]
          }}</span></app-button
        >
      </prismic-link>
    </container>
  </header>
</template>

<script>
import AppButton from "@/components/AppButton";
import Container from "@/components/Container";
import PrismicLink from "@/components/PrismicLink";
import gql from "graphql-tag";

export default {
  name: "AppHeader",
  components: {
    AppButton,
    Container,
    PrismicLink
  },
  data() {
    return {
      navOpen: false,
      content: {}
    };
  },
  methods: {
    activePath(link) {
      if (!link._meta) {
        return false;
      }

      const linkPath = link._meta.uid == "index" ? "/" : `/${link._meta.uid}`;

      return this.$route.path == linkPath;
    }
  },
  apollo: {
    content: {
      query: gql`
        query {
          header(uid: "header", lang: "en-gb") {
            navigation_left {
              ... on HeaderNavigation_left {
                link_copy
                link_url {
                  ... on Page {
                    _meta {
                      uid
                    }
                  }
                  ... on _ExternalLink {
                    url
                    target
                  }
                }
              }
            }
            navigation_right {
              ... on HeaderNavigation_right {
                link_copy
                link_url {
                  ... on Page {
                    _meta {
                      uid
                    }
                  }
                  ... on _ExternalLink {
                    url
                    target
                  }
                }
              }
            }
          }
        }
      `,
      update: data => data.header
    }
  }
};
</script>

<style lang="scss" scoped>
header {
  width: 100%;
  top: 32px;
  position: absolute;
  z-index: 1;

  @include mobile {
    top: 8px;
  }

  section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }

  nav {
    display: flex;

    .prismic-link {
      font-family: $font-secondary;
      font-size: rem-calc(14px);
      font-weight: normal;
      text-decoration: none;
      margin: 0 12px;
      color: $turquoise1;
      border-bottom: 8px solid transparent;
      padding-bottom: 19px;
      display: inline;

      @include mobile {
        border-bottom: 5px solid transparent;
        padding: 6px 14px;
        margin-left: 0;
      }

      &.active,
      &:active,
      &:hover {
        border-color: $turquoise1;
      }
    }
  }

  > .prismic-link {
    padding: 24px;
    position: absolute;
    right: 0;
    cursor: pointer;
  }
}
</style>
