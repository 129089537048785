<template>
  <div
    class="header"
    :style="{
      backgroundImage: content.header_background
        ? `url(${content.header_background.url})`
        : ''
    }"
  >
    <container>
      <collaborators-box
        :collaborators="content.collaborators"
        v-if="content.collaborators.length"
      />
      <prismic-text
        v-if="content.header_copy"
        :blocks="content.header_copy || []"
      />
    </container>
  </div>
</template>

<script>
import CollaboratorsBox from "@/components/CollaboratorsBox";
import Container from "@/components/Container";
import PrismicText from "@/components/PrismicText";

export default {
  name: "PageHeader",
  components: {
    CollaboratorsBox,
    Container,
    PrismicText
  },
  props: {
    content: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  background-color: $blue2;
  background-position: 50%;
  background-size: cover;
  color: $white1;

  section {
    margin: 0 auto;
    padding-top: 150px;
    padding-bottom: 80px;

    @include mobile {
      padding-top: 90px;
      padding-bottom: 20px;
    }

    ::v-deep {
      h2 {
        margin: 8px auto 64px;
        font-size: rem-calc(57px);

        @include mobile {
          font-size: rem-calc(34px);
          margin-bottom: 0;
        }
      }

      p {
        white-space: pre-wrap;
      }
    }

    .collaborators {
      max-width: 400px;
      margin-bottom: 20px;

      @include mobile {
        width: 100%;
        max-width: none;

        ::v-deep > div {
          justify-content: space-between;

          > a {
            max-width: 33%;
          }
        }
      }
    }
  }
}
</style>
