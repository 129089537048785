<template>
  <transition name="fade" mode="out-in" appear>
    <div v-if="!$apollo.loading">
      <template v-for="(section, index) in content.body">
        <page-header
          :key="`section${index}`"
          v-if="section.__typename == 'PageBodyPage_header'"
          :content="section.primary || {}"
          :founding-partners="section.fields.map(obj => obj.founding_partner)"
        />
        <prismic-text
          :key="`section${index}`"
          v-if="section.__typename == 'PageBodyText'"
          :blocks="section"
        />
        <slice-feature
          :key="`section${index}`"
          v-if="section.__typename == 'PageBodyFeature'"
          :theme="section.primary.feature_theme"
          :copy="section.primary.feature_copy || []"
          :button-link="section.primary.feature_link"
          :button-text="section.primary.feature_link_copy"
          :background-url="(section.primary.feature_background || {}).url"
          :image-url="(section.primary.feature_image || {}).url"
          :alignment="section.primary.feature_alignment"
        />
        <slice-schedule
          :key="`section${index}`"
          v-if="section.__typename == 'PageBodySchedule'"
          :theme="section.primary.schedule_theme"
          :full-info="section.primary.schedule_full_info"
          :copy="section.primary.schedule_copy || []"
          :button-link="section.primary.schedule_link"
          :button-text="section.primary.schedule_link_copy"
          :events="section.fields.map(field => field.event)"
        />
        <slice-speakers
          :key="`section${index}`"
          v-if="section.__typename == 'PageBodySpeakers'"
          :theme="section.primary.speakers_theme"
          :copy="section.primary.speakers_copy || []"
          :speakers="section.fields.map(field => field.speaker)"
        />
      </template>
    </div>
  </transition>
</template>

<script>
import SliceFeature from "@/components/SliceFeature";
import SliceSchedule from "@/components/SliceSchedule";
import SliceSpeakers from "@/components/SliceSpeakers";
import PageHeader from "@/components/PageHeader";
import PrismicText from "@/components/PrismicText";
import gql from "graphql-tag";

export default {
  name: "PrismicBody",
  components: {
    SliceFeature,
    SliceSchedule,
    SliceSpeakers,
    PageHeader,
    PrismicText
  },
  props: {
    uid: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      content: {}
    };
  },
  apollo: {
    content: {
      query() {
        return gql`
          query($uid: String!) {
            page(uid: $uid, lang: "en-gb") {
              body {
                ... on PageBodyFeature {
                  primary {
                    feature_theme
                    feature_copy
                    feature_link_copy
                    feature_link {
                      ... on Page {
                        _meta {
                          uid
                        }
                      }
                      ... on _ExternalLink {
                        url
                        target
                      }
                    }
                    feature_image
                    feature_alignment
                  }
                }
                ... on PageBodySchedule {
                  primary {
                    schedule_theme
                    schedule_full_info
                    schedule_copy
                    schedule_link_copy
                    schedule_link {
                      ... on Page {
                        _meta {
                          uid
                        }
                      }
                      ... on _ExternalLink {
                        url
                        target
                      }
                    }
                  }
                  fields {
                    ... on PageBodyScheduleFields {
                      event {
                        ... on Event {
                          title
                          start_time
                          end_time
                          other_tz {
                            other_tz_start_time
                            other_tz_end_time
                            other_tz_abbr
                          }
                          duration
                          subtitle
                          description
                          speakers {
                            ... on EventSpeakers {
                              group
                              speaker {
                                ... on Speaker {
                                  name
                                  title
                                  bio
                                  photo {
                                    ... on _ImageLink {
                                      url
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                ... on PageBodySpeakers {
                  primary {
                    speakers_theme
                    speakers_copy
                  }
                  fields {
                    ... on PageBodySpeakersFields {
                      speaker {
                        ... on Speaker {
                          name
                          title
                          bio
                          photo {
                            ... on _ImageLink {
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `;
      },
      variables() {
        return {
          uid: this.uid
        };
      },
      skip() {
        return !this.uid;
      },
      update(data) {
        return data.page;
      }
    }
  }
};
</script>
