<template>
  <article v-if="!$apollo.loading">
    <page-header :content="content" />
    <prismic-body :uid="uid" />
  </article>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PrismicBody from "@/components/PrismicBody";
import gql from "graphql-tag";

export default {
  components: {
    PageHeader,
    PrismicBody
  },
  props: {
    uid: {
      type: String,
      default: ""
    }
  },
  metaInfo() {
    return {
      title: this.content.title ? this.content.title[0].text : null
    };
  },
  data() {
    return {
      content: {}
    };
  },
  apollo: {
    content: {
      query: gql`
        query($uid: String!) {
          page(uid: $uid, lang: "en-gb") {
            title
            header_copy
            header_background {
              ... on _ImageLink {
                url
              }
            }
            collaborators {
              ... on PageCollaborators {
                link {
                  ... on Page {
                    _meta {
                      uid
                    }
                  }
                  ... on _ExternalLink {
                    url
                    target
                  }
                }
                logo {
                  ... on _ImageLink {
                    url
                  }
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          uid: this.uid
        };
      },
      skip() {
        return !this.uid;
      },
      update: data => data.page
    }
  },
  watch: {
    content() {
      if (!this.content) {
        this.$router.push("/not-found");
      }
    }
  }
};
</script>
